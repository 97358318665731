export const DELIVERY_STATUS = {
  default: '未處理',
  packaging: '打包中',
  tallying: '等待出貨(尚未填入追蹤碼)',
  pending: '出貨中',
  transit: '＊已出貨',
  pickup: '運送中',
  delivered: '已送達',
  undelivered: '未送達',
  notfound: '無追蹤中包裹',
  exception: '例外事故',
  expired: '過期',
};

export const SELF_DELIVERY_STATUS = {
  default: '未處理',
  packaging: '打包中',
  tallying: '等待出貨',
  transit: '＊可自取',

  pending: '出貨中',
  pickup: '已出貨',
  notfound: '無追蹤中包裹',
  delivered: '已自取',
  undelivered: '未送達',
  exception: '例外事故',
  expired: '過期',
};

export function getStatusSet({type}) {
  if (type === 'self_pick') {
    return {
      default: SELF_DELIVERY_STATUS.default,
      packaging: SELF_DELIVERY_STATUS.packaging,
      tallying: SELF_DELIVERY_STATUS.tallying,
      transit: SELF_DELIVERY_STATUS.transit,
      delivered: SELF_DELIVERY_STATUS.delivered,
    };
  }
  if (type === 'ezship') {
    return {
      ...DELIVERY_STATUS,
      tallying: '等待出貨',
    };
  }
  return DELIVERY_STATUS;
}

export function displayStatus(instance) {
  let label = '';
  let stateSet = getStatusSet({type: instance.logistics_type});
  try {
    if (instance) {
      label = stateSet[instance.logistics_status];
    }
  } catch (error) {}

  return label || instance.logistics_status;
}
